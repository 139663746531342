/**
 * Converts a given value to a percentage string.
 *
 * @param {Number} $value - The value to be converted to a percentage.
 * @return {String} - The percentage representation of the value.
 */
/**
 * Generates a transparent version of the given color.
 *
 * @param {Color} $color - The base color to be made transparent.
 * @param {Number} $alpha - The level of transparency, ranging from 0 (fully transparent) to 1 (fully opaque). Default is 1.
 * @return {Color} - The resulting color with the specified transparency.
 */
.flag {
  position: relative;
  display: inline-block;
  height: 2.5rem;
  aspect-ratio: 1.33333;
  background: no-repeat center/cover;
  box-shadow: var(--tblr-box-shadow-border);
  border-radius: var(--tblr-border-radius);
  vertical-align: bottom;
}
.flag.flag-country-np {
  box-shadow: none;
  border-radius: 0;
}

.flag-country-ad {
  background-image: url("../img/flags/ad.svg");
}

.flag-country-af {
  background-image: url("../img/flags/af.svg");
}

.flag-country-ae {
  background-image: url("../img/flags/ae.svg");
}

.flag-country-afrun {
  background-image: url("../img/flags/afrun.svg");
}

.flag-country-ag {
  background-image: url("../img/flags/ag.svg");
}

.flag-country-ai {
  background-image: url("../img/flags/ai.svg");
}

.flag-country-al {
  background-image: url("../img/flags/al.svg");
}

.flag-country-am {
  background-image: url("../img/flags/am.svg");
}

.flag-country-ao {
  background-image: url("../img/flags/ao.svg");
}

.flag-country-aq {
  background-image: url("../img/flags/aq.svg");
}

.flag-country-ar {
  background-image: url("../img/flags/ar.svg");
}

.flag-country-as {
  background-image: url("../img/flags/as.svg");
}

.flag-country-at {
  background-image: url("../img/flags/at.svg");
}

.flag-country-au {
  background-image: url("../img/flags/au.svg");
}

.flag-country-aw {
  background-image: url("../img/flags/aw.svg");
}

.flag-country-ax {
  background-image: url("../img/flags/ax.svg");
}

.flag-country-az {
  background-image: url("../img/flags/az.svg");
}

.flag-country-ba {
  background-image: url("../img/flags/ba.svg");
}

.flag-country-bb {
  background-image: url("../img/flags/bb.svg");
}

.flag-country-bd {
  background-image: url("../img/flags/bd.svg");
}

.flag-country-be {
  background-image: url("../img/flags/be.svg");
}

.flag-country-bf {
  background-image: url("../img/flags/bf.svg");
}

.flag-country-bg {
  background-image: url("../img/flags/bg.svg");
}

.flag-country-bh {
  background-image: url("../img/flags/bh.svg");
}

.flag-country-bi {
  background-image: url("../img/flags/bi.svg");
}

.flag-country-bj {
  background-image: url("../img/flags/bj.svg");
}

.flag-country-bl {
  background-image: url("../img/flags/bl.svg");
}

.flag-country-bm {
  background-image: url("../img/flags/bm.svg");
}

.flag-country-bn {
  background-image: url("../img/flags/bn.svg");
}

.flag-country-bo {
  background-image: url("../img/flags/bo.svg");
}

.flag-country-bq-bo {
  background-image: url("../img/flags/bq-bo.svg");
}

.flag-country-bq-sa {
  background-image: url("../img/flags/bq-sa.svg");
}

.flag-country-bq-se {
  background-image: url("../img/flags/bq-se.svg");
}

.flag-country-br {
  background-image: url("../img/flags/br.svg");
}

.flag-country-bs {
  background-image: url("../img/flags/bs.svg");
}

.flag-country-bt {
  background-image: url("../img/flags/bt.svg");
}

.flag-country-bv {
  background-image: url("../img/flags/bv.svg");
}

.flag-country-bw {
  background-image: url("../img/flags/bw.svg");
}

.flag-country-by {
  background-image: url("../img/flags/by.svg");
}

.flag-country-bz {
  background-image: url("../img/flags/bz.svg");
}

.flag-country-ca {
  background-image: url("../img/flags/ca.svg");
}

.flag-country-cc {
  background-image: url("../img/flags/cc.svg");
}

.flag-country-cd {
  background-image: url("../img/flags/cd.svg");
}

.flag-country-cf {
  background-image: url("../img/flags/cf.svg");
}

.flag-country-cg {
  background-image: url("../img/flags/cg.svg");
}

.flag-country-ch {
  background-image: url("../img/flags/ch.svg");
}

.flag-country-ci {
  background-image: url("../img/flags/ci.svg");
}

.flag-country-ck {
  background-image: url("../img/flags/ck.svg");
}

.flag-country-cl {
  background-image: url("../img/flags/cl.svg");
}

.flag-country-cm {
  background-image: url("../img/flags/cm.svg");
}

.flag-country-cn {
  background-image: url("../img/flags/cn.svg");
}

.flag-country-co {
  background-image: url("../img/flags/co.svg");
}

.flag-country-cr {
  background-image: url("../img/flags/cr.svg");
}

.flag-country-cu {
  background-image: url("../img/flags/cu.svg");
}

.flag-country-cv {
  background-image: url("../img/flags/cv.svg");
}

.flag-country-cw {
  background-image: url("../img/flags/cw.svg");
}

.flag-country-cx {
  background-image: url("../img/flags/cx.svg");
}

.flag-country-cy {
  background-image: url("../img/flags/cy.svg");
}

.flag-country-cz {
  background-image: url("../img/flags/cz.svg");
}

.flag-country-de {
  background-image: url("../img/flags/de.svg");
}

.flag-country-dj {
  background-image: url("../img/flags/dj.svg");
}

.flag-country-dk {
  background-image: url("../img/flags/dk.svg");
}

.flag-country-dm {
  background-image: url("../img/flags/dm.svg");
}

.flag-country-do {
  background-image: url("../img/flags/do.svg");
}

.flag-country-dz {
  background-image: url("../img/flags/dz.svg");
}

.flag-country-ec {
  background-image: url("../img/flags/ec.svg");
}

.flag-country-ee {
  background-image: url("../img/flags/ee.svg");
}

.flag-country-eg {
  background-image: url("../img/flags/eg.svg");
}

.flag-country-eh {
  background-image: url("../img/flags/eh.svg");
}

.flag-country-er {
  background-image: url("../img/flags/er.svg");
}

.flag-country-es {
  background-image: url("../img/flags/es.svg");
}

.flag-country-et {
  background-image: url("../img/flags/et.svg");
}

.flag-country-eu {
  background-image: url("../img/flags/eu.svg");
}

.flag-country-fi {
  background-image: url("../img/flags/fi.svg");
}

.flag-country-fj {
  background-image: url("../img/flags/fj.svg");
}

.flag-country-fk {
  background-image: url("../img/flags/fk.svg");
}

.flag-country-fm {
  background-image: url("../img/flags/fm.svg");
}

.flag-country-fo {
  background-image: url("../img/flags/fo.svg");
}

.flag-country-fr {
  background-image: url("../img/flags/fr.svg");
}

.flag-country-ga {
  background-image: url("../img/flags/ga.svg");
}

.flag-country-gb-eng {
  background-image: url("../img/flags/gb-eng.svg");
}

.flag-country-gb-sct {
  background-image: url("../img/flags/gb-sct.svg");
}

.flag-country-gb {
  background-image: url("../img/flags/gb.svg");
}

.flag-country-gb-wls {
  background-image: url("../img/flags/gb-wls.svg");
}

.flag-country-gb-nir {
  background-image: url("../img/flags/gb-nir.svg");
}

.flag-country-gd {
  background-image: url("../img/flags/gd.svg");
}

.flag-country-ge {
  background-image: url("../img/flags/ge.svg");
}

.flag-country-gf {
  background-image: url("../img/flags/gf.svg");
}

.flag-country-gg {
  background-image: url("../img/flags/gg.svg");
}

.flag-country-gh {
  background-image: url("../img/flags/gh.svg");
}

.flag-country-gi {
  background-image: url("../img/flags/gi.svg");
}

.flag-country-gl {
  background-image: url("../img/flags/gl.svg");
}

.flag-country-gm {
  background-image: url("../img/flags/gm.svg");
}

.flag-country-gn {
  background-image: url("../img/flags/gn.svg");
}

.flag-country-gp {
  background-image: url("../img/flags/gp.svg");
}

.flag-country-gq {
  background-image: url("../img/flags/gq.svg");
}

.flag-country-gr {
  background-image: url("../img/flags/gr.svg");
}

.flag-country-gs {
  background-image: url("../img/flags/gs.svg");
}

.flag-country-gt {
  background-image: url("../img/flags/gt.svg");
}

.flag-country-gu {
  background-image: url("../img/flags/gu.svg");
}

.flag-country-gw {
  background-image: url("../img/flags/gw.svg");
}

.flag-country-gy {
  background-image: url("../img/flags/gy.svg");
}

.flag-country-hk {
  background-image: url("../img/flags/hk.svg");
}

.flag-country-hm {
  background-image: url("../img/flags/hm.svg");
}

.flag-country-hn {
  background-image: url("../img/flags/hn.svg");
}

.flag-country-hr {
  background-image: url("../img/flags/hr.svg");
}

.flag-country-ht {
  background-image: url("../img/flags/ht.svg");
}

.flag-country-hu {
  background-image: url("../img/flags/hu.svg");
}

.flag-country-id {
  background-image: url("../img/flags/id.svg");
}

.flag-country-ie {
  background-image: url("../img/flags/ie.svg");
}

.flag-country-il {
  background-image: url("../img/flags/il.svg");
}

.flag-country-im {
  background-image: url("../img/flags/im.svg");
}

.flag-country-in {
  background-image: url("../img/flags/in.svg");
}

.flag-country-io {
  background-image: url("../img/flags/io.svg");
}

.flag-country-iq {
  background-image: url("../img/flags/iq.svg");
}

.flag-country-ir {
  background-image: url("../img/flags/ir.svg");
}

.flag-country-is {
  background-image: url("../img/flags/is.svg");
}

.flag-country-it {
  background-image: url("../img/flags/it.svg");
}

.flag-country-je {
  background-image: url("../img/flags/je.svg");
}

.flag-country-jm {
  background-image: url("../img/flags/jm.svg");
}

.flag-country-jo {
  background-image: url("../img/flags/jo.svg");
}

.flag-country-jp {
  background-image: url("../img/flags/jp.svg");
}

.flag-country-ke {
  background-image: url("../img/flags/ke.svg");
}

.flag-country-kg {
  background-image: url("../img/flags/kg.svg");
}

.flag-country-kh {
  background-image: url("../img/flags/kh.svg");
}

.flag-country-ki {
  background-image: url("../img/flags/ki.svg");
}

.flag-country-km {
  background-image: url("../img/flags/km.svg");
}

.flag-country-kn {
  background-image: url("../img/flags/kn.svg");
}

.flag-country-kp {
  background-image: url("../img/flags/kp.svg");
}

.flag-country-kr {
  background-image: url("../img/flags/kr.svg");
}

.flag-country-kw {
  background-image: url("../img/flags/kw.svg");
}

.flag-country-ky {
  background-image: url("../img/flags/ky.svg");
}

.flag-country-kz {
  background-image: url("../img/flags/kz.svg");
}

.flag-country-la {
  background-image: url("../img/flags/la.svg");
}

.flag-country-lb {
  background-image: url("../img/flags/lb.svg");
}

.flag-country-lc {
  background-image: url("../img/flags/lc.svg");
}

.flag-country-li {
  background-image: url("../img/flags/li.svg");
}

.flag-country-lk {
  background-image: url("../img/flags/lk.svg");
}

.flag-country-lr {
  background-image: url("../img/flags/lr.svg");
}

.flag-country-ls {
  background-image: url("../img/flags/ls.svg");
}

.flag-country-lt {
  background-image: url("../img/flags/lt.svg");
}

.flag-country-lu {
  background-image: url("../img/flags/lu.svg");
}

.flag-country-lv {
  background-image: url("../img/flags/lv.svg");
}

.flag-country-ly {
  background-image: url("../img/flags/ly.svg");
}

.flag-country-ma {
  background-image: url("../img/flags/ma.svg");
}

.flag-country-mc {
  background-image: url("../img/flags/mc.svg");
}

.flag-country-md {
  background-image: url("../img/flags/md.svg");
}

.flag-country-me {
  background-image: url("../img/flags/me.svg");
}

.flag-country-mf {
  background-image: url("../img/flags/mf.svg");
}

.flag-country-mg {
  background-image: url("../img/flags/mg.svg");
}

.flag-country-mh {
  background-image: url("../img/flags/mh.svg");
}

.flag-country-mk {
  background-image: url("../img/flags/mk.svg");
}

.flag-country-ml {
  background-image: url("../img/flags/ml.svg");
}

.flag-country-mm {
  background-image: url("../img/flags/mm.svg");
}

.flag-country-mn {
  background-image: url("../img/flags/mn.svg");
}

.flag-country-mo {
  background-image: url("../img/flags/mo.svg");
}

.flag-country-mp {
  background-image: url("../img/flags/mp.svg");
}

.flag-country-mq {
  background-image: url("../img/flags/mq.svg");
}

.flag-country-mr {
  background-image: url("../img/flags/mr.svg");
}

.flag-country-ms {
  background-image: url("../img/flags/ms.svg");
}

.flag-country-mt {
  background-image: url("../img/flags/mt.svg");
}

.flag-country-mu {
  background-image: url("../img/flags/mu.svg");
}

.flag-country-mv {
  background-image: url("../img/flags/mv.svg");
}

.flag-country-mw {
  background-image: url("../img/flags/mw.svg");
}

.flag-country-mx {
  background-image: url("../img/flags/mx.svg");
}

.flag-country-my {
  background-image: url("../img/flags/my.svg");
}

.flag-country-mz {
  background-image: url("../img/flags/mz.svg");
}

.flag-country-na {
  background-image: url("../img/flags/na.svg");
}

.flag-country-nc {
  background-image: url("../img/flags/nc.svg");
}

.flag-country-ne {
  background-image: url("../img/flags/ne.svg");
}

.flag-country-nf {
  background-image: url("../img/flags/nf.svg");
}

.flag-country-ng {
  background-image: url("../img/flags/ng.svg");
}

.flag-country-ni {
  background-image: url("../img/flags/ni.svg");
}

.flag-country-nl {
  background-image: url("../img/flags/nl.svg");
}

.flag-country-no {
  background-image: url("../img/flags/no.svg");
}

.flag-country-np {
  background-image: url("../img/flags/np.svg");
}

.flag-country-nr {
  background-image: url("../img/flags/nr.svg");
}

.flag-country-nu {
  background-image: url("../img/flags/nu.svg");
}

.flag-country-nz {
  background-image: url("../img/flags/nz.svg");
}

.flag-country-om {
  background-image: url("../img/flags/om.svg");
}

.flag-country-pa {
  background-image: url("../img/flags/pa.svg");
}

.flag-country-pe {
  background-image: url("../img/flags/pe.svg");
}

.flag-country-pf {
  background-image: url("../img/flags/pf.svg");
}

.flag-country-pg {
  background-image: url("../img/flags/pg.svg");
}

.flag-country-ph {
  background-image: url("../img/flags/ph.svg");
}

.flag-country-pk {
  background-image: url("../img/flags/pk.svg");
}

.flag-country-pl {
  background-image: url("../img/flags/pl.svg");
}

.flag-country-pm {
  background-image: url("../img/flags/pm.svg");
}

.flag-country-pn {
  background-image: url("../img/flags/pn.svg");
}

.flag-country-pr {
  background-image: url("../img/flags/pr.svg");
}

.flag-country-ps {
  background-image: url("../img/flags/ps.svg");
}

.flag-country-pt {
  background-image: url("../img/flags/pt.svg");
}

.flag-country-pw {
  background-image: url("../img/flags/pw.svg");
}

.flag-country-py {
  background-image: url("../img/flags/py.svg");
}

.flag-country-qa {
  background-image: url("../img/flags/qa.svg");
}

.flag-country-rainbow {
  background-image: url("../img/flags/rainbow.svg");
}

.flag-country-re {
  background-image: url("../img/flags/re.svg");
}

.flag-country-ro {
  background-image: url("../img/flags/ro.svg");
}

.flag-country-rs {
  background-image: url("../img/flags/rs.svg");
}

.flag-country-ru {
  background-image: url("../img/flags/ru.svg");
}

.flag-country-rw {
  background-image: url("../img/flags/rw.svg");
}

.flag-country-sa {
  background-image: url("../img/flags/sa.svg");
}

.flag-country-sb {
  background-image: url("../img/flags/sb.svg");
}

.flag-country-sc {
  background-image: url("../img/flags/sc.svg");
}

.flag-country-sd {
  background-image: url("../img/flags/sd.svg");
}

.flag-country-se {
  background-image: url("../img/flags/se.svg");
}

.flag-country-sg {
  background-image: url("../img/flags/sg.svg");
}

.flag-country-sh {
  background-image: url("../img/flags/sh.svg");
}

.flag-country-si {
  background-image: url("../img/flags/si.svg");
}

.flag-country-sj {
  background-image: url("../img/flags/sj.svg");
}

.flag-country-sk {
  background-image: url("../img/flags/sk.svg");
}

.flag-country-sl {
  background-image: url("../img/flags/sl.svg");
}

.flag-country-sm {
  background-image: url("../img/flags/sm.svg");
}

.flag-country-sn {
  background-image: url("../img/flags/sn.svg");
}

.flag-country-so {
  background-image: url("../img/flags/so.svg");
}

.flag-country-sr {
  background-image: url("../img/flags/sr.svg");
}

.flag-country-ss {
  background-image: url("../img/flags/ss.svg");
}

.flag-country-st {
  background-image: url("../img/flags/st.svg");
}

.flag-country-sv {
  background-image: url("../img/flags/sv.svg");
}

.flag-country-sx {
  background-image: url("../img/flags/sx.svg");
}

.flag-country-sy {
  background-image: url("../img/flags/sy.svg");
}

.flag-country-sz {
  background-image: url("../img/flags/sz.svg");
}

.flag-country-tc {
  background-image: url("../img/flags/tc.svg");
}

.flag-country-td {
  background-image: url("../img/flags/td.svg");
}

.flag-country-tf {
  background-image: url("../img/flags/tf.svg");
}

.flag-country-tg {
  background-image: url("../img/flags/tg.svg");
}

.flag-country-th {
  background-image: url("../img/flags/th.svg");
}

.flag-country-tj {
  background-image: url("../img/flags/tj.svg");
}

.flag-country-tk {
  background-image: url("../img/flags/tk.svg");
}

.flag-country-tl {
  background-image: url("../img/flags/tl.svg");
}

.flag-country-tm {
  background-image: url("../img/flags/tm.svg");
}

.flag-country-tn {
  background-image: url("../img/flags/tn.svg");
}

.flag-country-to {
  background-image: url("../img/flags/to.svg");
}

.flag-country-tr {
  background-image: url("../img/flags/tr.svg");
}

.flag-country-tt {
  background-image: url("../img/flags/tt.svg");
}

.flag-country-tv {
  background-image: url("../img/flags/tv.svg");
}

.flag-country-tw {
  background-image: url("../img/flags/tw.svg");
}

.flag-country-tz {
  background-image: url("../img/flags/tz.svg");
}

.flag-country-ua {
  background-image: url("../img/flags/ua.svg");
}

.flag-country-ug {
  background-image: url("../img/flags/ug.svg");
}

.flag-country-um {
  background-image: url("../img/flags/um.svg");
}

.flag-country-unasur {
  background-image: url("../img/flags/unasur.svg");
}

.flag-country-us {
  background-image: url("../img/flags/us.svg");
}

.flag-country-uy {
  background-image: url("../img/flags/uy.svg");
}

.flag-country-uz {
  background-image: url("../img/flags/uz.svg");
}

.flag-country-va {
  background-image: url("../img/flags/va.svg");
}

.flag-country-vc {
  background-image: url("../img/flags/vc.svg");
}

.flag-country-ve {
  background-image: url("../img/flags/ve.svg");
}

.flag-country-vg {
  background-image: url("../img/flags/vg.svg");
}

.flag-country-vi {
  background-image: url("../img/flags/vi.svg");
}

.flag-country-vn {
  background-image: url("../img/flags/vn.svg");
}

.flag-country-vu {
  background-image: url("../img/flags/vu.svg");
}

.flag-country-wf {
  background-image: url("../img/flags/wf.svg");
}

.flag-country-ws {
  background-image: url("../img/flags/ws.svg");
}

.flag-country-ye {
  background-image: url("../img/flags/ye.svg");
}

.flag-country-za {
  background-image: url("../img/flags/za.svg");
}

.flag-country-zm {
  background-image: url("../img/flags/zm.svg");
}

.flag-country-zw {
  background-image: url("../img/flags/zw.svg");
}

.flag-xxs {
  height: 1rem;
}

.flag-xs {
  height: 1.25rem;
}

.flag-sm {
  height: 2rem;
}

.flag-md {
  height: 2.5rem;
}

.flag-lg {
  height: 3rem;
}

.flag-xl {
  height: 5rem;
}

.flag-2xl {
  height: 7rem;
}
